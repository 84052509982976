@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --search-field-color-border: #{$default-primary-base-color};
    --search-field-color-placeholder: #{$default-primary-base-color};
    --search-field-color: #{$default-primary-base-color};

    --search-input-desktop-border-color: #{$default-primary-base-color};
}
