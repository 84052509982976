@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --newsletter-background: #{$default-secondary-base-color};
    --newsletter-agreement-link-color: #{$default-primary-base-color};
    --consents-button-color: #{$default-primary-base-color};
}

.Newsletter {
    overflow: hidden;
    &-Background {
        @include desktop {
            width: 479px;
            height: 209px;
            position: absolute;
            left: 0;
            transform: translateX(calc(-50% + 40px));
        }
    }

    &-Container {
        background: var(--newsletter-background);
        margin-bottom: 20px;

        @include desktop {
            padding: 55px 0 45px;
            width: 100vw;
        }

        @include tablet {
            padding: 55px 0;
        }

        @include mobile {
            padding: 50px 0;
        }
    }

    &-Wrapper {
        display: flex;
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);

        @include desktop {
            padding: 0 32px;
            margin: 0 auto;
            max-width: var(--content-wrapper-width);
        }

        @include mobileAndTablet {
            flex-direction: column;
            align-items: center;
            padding: 0 15px;
        }
    }

    &-InfoWrapper,
    &-FormWrapper {
        @include desktop {
            width: 50%;
        }
    }

    &-InfoWrapper {
        @include tablet {
            margin-bottom: 35px;
        }

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &-Info {
        @include desktop {
            max-width: 500px;
            padding: 0 15px;
            margin: 0 auto;
        }

        @include mobileAndTablet {
            text-align: center;
        }

        @include mobile {
            max-width: 360px;
        }

        h2,
        p {
            color: $white;
        }

        span {
            color: $white;
        }

        h2 {
            font-size: 32px;
            line-height: 44px;
            margin: 0 0 10px;
            text-transform: inherit;

            @include mobile {
                padding: 0 20px;
            }
        }

        p {
            font-size: 15px;
            line-height: 22px;
            margin: 0 auto;
            @include from-tablet {
                margin: 0;
                max-width: 399px;
            }
        }
    }

    &-FormWrapper {
        @include mobileAndTablet {
            width: 100%;
        }
        @include tablet {
            max-width: 460px;
        }

        @include mobile {
            max-width: 360px;
        }
    }

    .NewsletterSubscription {
        @include desktop {
            max-width: 465px;
            margin: 0 auto;
        }

        .Form {
            &_isInvalid {
                margin-bottom: 0;
            }
        }

        .FieldForm-Fieldset {
            .FieldForm-Fields {
                input {
                    height: 64px;
                    padding: 14px 170px 14px 40px;

                    @include mobile {
                        padding: 14px 120px 14px 25px;
                    }
                }

                .Field-Message {
                    margin-top: 0;
                }
            }
        }
        .Button {
            position: absolute;
            top: 8px;
            right: 8px;
            margin: 0;
            width: 152px;
            --button-border-radius: 7px;

            @include mobile {
                width: 108px;
                padding: 5px 10px;
            }
        }

        .Consents {
            .Field-CheckboxLabel {
                margin-top: 25px;
                a, p {
                    font-family: $font-secondary;
                    font-weight: normal;
                    font-size: 13px;
                    color: $white;
                    line-height: 150%;
                }

                a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }

            &-Label {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 13px;
                color: $white;
            }

            .Field-Message {
                position: static;
                margin-left: 25px;
            }

            .ShowMoreLessContent-Button {
                color: var(--consents-button-color);
            }
        }
    }

    &-Figure {
        position: absolute;

        &_First {
            @include desktop {
                width: 165px;
                height: 165px;
                bottom: calc(100% - 95px);
                left: 25px;
                transform: translate(-50%, -15%);
            }

            @include tablet {
                width: 165px;
                height: 165px;
                bottom: calc(100% - 95px);
                left: 25px;
                transform: translate(-50%, -15%);
            }

            @include mobile {
                width: 110px;
                height: 110px;
                left: 25px;
                top: 0;
                transform: translate(-80%, -10%);
            }
        }

        &_Second {
            @include desktop {
                width: 185px;
                height: 185px;
                top: calc(100% - 185px);
                right: 0;
                transform: translate(50%, 65%);
            }

            @include tablet {
                width: 185px;
                height: 185px;
                top: calc(100% - 185px);
                right: 0;
                transform: translate(35%, 65%);
            }

            @include mobile {
                width: 115px;
                height: 115px;
                top: calc(100% - 80px);
                right: 0;
                transform: translate(45%, 65%);
            }
        }
    }
}
