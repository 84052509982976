@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.HeroSlider {
    &-Wrapper {
        background: linear-gradient(0, rgba(0, 92, 170, 0.045) 0%, rgba(0, 92, 170, 0) 100%);
    }
    &-SlideHeader {
        color: $default-primary-base-color;
        text-transform: lowercase;
        &:before {
            display: none;
        }
    }
    &-CTAButton {
        min-width: 182px;
        font-size: 16px;
        line-height: 140%;
    }
}
