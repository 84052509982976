@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --slider-dots-offset: 20px;
    --slider-arrow-offset: 32px;
    --slider-height: 400px;
    --slider-height-tablet: 400px;
    --slider-height-desktop: 400px;
    --slider-max-width: calc(100% + 28px);
    --slider-arrow-background: #{$blue50};
    --slider-heading-color: #{$blue50};
}

.AdvoxSlider {
    max-width: var(--slider-max-width);

    &_variant_default {
        --slider-height: 300px;
        --slider-height-tablet: 300px;
        --slider-height-desktop: 300px;
        max-width: var(--content-wrapper-width);
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
        margin: 0 auto;

        .Slider-Placeholder {
            @include placeholder(500px);
        }
        .slick-arrow {
            @include from-tablet {
                top: calc((var(--slider-height-tablet) / 2 - 20px));
            }

            @include desktop {
                top: calc((var(--slider-height-desktop) / 2 - 20px));
            }
        }
        .Slider-Slide {
            min-height: 500px;
        }

        .slick-slide > div {
            margin: 0 10px;
            padding: 24px 0;
        }

        .slick-list {
            margin: 0 -10px;
        }
        .slick-prev {
            left: -20px;
            @include widest-desktop {
                left: -65px;
            }
        }
        .slick-next {
            right: -20px;
            @include widest-desktop {
                right: -65px;
            }
        }
        .AdvoxSlider-Heading {
            font-size: 30px;
            margin: 10px;
        }
        .slick-list {
            @include mobile {
                padding: 0 20% 0 0 !important;
            }
        }
    }
    &_variant_products {
        --slider-height: 500px;
        --slider-height-tablet: 500px;
        --slider-height-desktop: 500px;
        margin: 0 auto;
    }
    &-Heading {
        text-align: center;
        color: var(--slider-heading-color);
        text-transform: none;

        @include from-tablet {
            margin-bottom: 50px;
        }

        &_headingSide_left {
            text-align: left;
        }

        &_headingSide_right {
            text-align: right;
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        overflow: hidden;
    }

    .slick-slider {
        height: 100%;
        overflow-y: visible;
        margin: 0 auto;
    }

    .slick-dots {
        position: absolute;
        top: calc(var(--slider-height) + var(--slider-dots-offset));
        height: 13px;

        @include from-tablet {
            top: calc(var(--slider-height-tablet) + var(--slider-dots-offset));
        }

        @include desktop {
            top: calc(var(--slider-height-desktop) - var(--slider-dots-offset) - 10px);
        }
    }
    .slick-arrow {
        top: calc((var(--slider-height) / 2));
        transform: translateY(-50%);
        background: var(--slider-arrow-background);
        width: 32px;
        height: 48px;
        filter: drop-shadow(0px 10px 15px rgba(63, 60, 234, 0.15));
        z-index: 9;

        @include from-tablet {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            top: calc((var(--slider-height-tablet) / 2));
        }

        @include desktop {
            top: calc((var(--slider-height-desktop) / 2));
        }

        &:before {
            display: none;
        }

        &:hover {
            background: var(--slider-arrow-background);
        }

        svg {
            position: relative;
            fill: $white;
            width: 13px;

            @include from-tablet {
                top: -5px;
            }
        }
    }
    .slick-prev {
        left: -20px;
        border-radius: 0 24px 24px 0;

        @include from-tablet {
            border-radius: 100%;
        }

        @include wider-desktop {
            left: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset));
        }

        svg {
            right: -5px;

            @include from-tablet {
                right: -13px;
            }
        }
    }
    .slick-next {
        right: -20px;
        border-radius: 24px 0 0 24px;

        @include from-tablet {
            border-radius: 100%;
        }

        @include wider-desktop {
            right: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset));
        }

        svg {
            left: 13px;
        }
    }
    &_variant_hero {
        --slider-height: 485px;
        --slider-height-tablet: 292px;
        --slider-height-desktop: 330px;

        .slick-arrow {
            --slider-arrow-background: #{$orange50};
        }

        .slick-list {
            @include from-tablet {
                border-radius: 10px;
            }
        }

        @include desktop {
            height: calc(var(--slider-height-desktop) + 60px);
        }

        ul.slick-dots {
            bottom: -40px;
            width: 100%;
            li {
                display: inline-block;
            }
            @include tablet {
                bottom: -32px;
            }

            @include desktop {
                bottom: 0;
                right: 0;
                top: calc(var(--slider-height-desktop) - var(--slider-dots-offset) + 50px);
            }

            @include large-screen {
                height: unset;
                bottom: unset;
                top: 50%;
                transform: translateY(calc(-50% - 15px));
                right: 15px;
                left: unset;
                width: 12px;
                display: block;
            }

            li {
                @include large-screen {
                    display: block;
                    margin: 8px 0;
                }
            }
        }

        .slick-prev {
            @include tablet {
                left: -20px;
            }
            @include widest-desktop {
                left: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset) - 20px);
            }
        }

        .slick-next {
            @include tablet {
                right: -20px;
            }
            @include widest-desktop {
                right: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset) - 20px);
            }
        }
    }

    .slick-dots {
        position: absolute;
        height: 13px;
        width: 13px;
        top: unset;

        @include desktop {
            top: calc(var(--slider-height-desktop) - var(--slider-dots-offset) - 10px);
        }

        li {
            display: inline-block;
            border-radius: 50%;
            background: $orange50;
            opacity: 0.2;
            margin: 0 5px;
            cursor: pointer;

            &:before {
                display: none;
            }

            &.slick-active {
                opacity: 1;
                background: $orange50;
            }
        }
    }

    .slick-slider {
        .slick-list {
            overflow: hidden !important;
        }
    }
}
