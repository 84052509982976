@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --field-label-container-color: #{$default-primary-base-color};
}

.Field {
    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_select {
            input:not([value='']),
            input:focus,
            textarea:not(:empty),
            textarea:focus,
            .FieldSelect {
                --input-background-color: #{$white};
                --input-border-color: #{$default-primary-base-color};
            }
            input:not([value='']),
            textarea:not(:empty) {
                & ~ .Field-LabelContainer {
                    label {
                        color: #{$grey50};
                    }
                }
            }
            input[value='']:focus,
            textarea:empty:focus {
                & ~ .Field-LabelContainer {
                    label {
                        color: #{$grey50};
                    }
                }
            }
            &.Field_isValid {
                input,
                textarea {
                    --primary-success-color: #{$default-primary-base-color};
                    &:focus {
                        --primary-success-color: #{$default-primary-base-color};
                    }
                }
            }
        }
    }
    &_hasError {
        input:not([value='']),
        textarea:not(:empty) {
            & ~ .Field-LabelContainer {
                label {
                    color: $red;
                }
            }
        }
        input[value='']:focus,
        textarea:empty:focus {
            & ~ .Field-LabelContainer {
                label {
                    color: $red;
                }
            }
        }
    }
}
