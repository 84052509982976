@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.CarouselScroll {
    flex-direction: column;
    height: calc(100% + 40px);
    margin-block-start: -20px;

    &-ContentWrapper {
        flex: 1;
        flex-direction: column;
        height: 100%;
        width: 70px;
    }

    &-Content {
        flex-direction: column;
        transform: translateY(var(--translateY));
    }
}
