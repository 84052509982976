@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.SeoText {
    --seo-text-heading-color: #{$default-secondary-base-color};
    &-Heading {
        text-transform: lowercase;
    }

    p,
    .Paragraph {
        font-size: 15px;
        line-height: 150%;
    }
}
