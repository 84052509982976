@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --store-switcher-label-color: #{$purple50};
    --store-switcher-button-color: #{$purple50};
    --store-switcher-line-background: #{$purple50};
    --store-switcher-color: #{$default-primary-base-color};
}
[dir='ltr'] .StoreSwitcher {
    &-Title {
        @include desktop {
            --store-switcher-color: #{$default-primary-base-color};
        }
    }

    .ChevronIcon {
        @include desktop {
            --store-switcher-color: #{$default-primary-base-color};
        }
    }
}
