@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ContactForm {
    .Button {
        font-family: $font-primary;
    }

    .Recaptcha {
        margin-block-end: 40px;
    }

    .Field_type_checkbox {
        margin-bottom: 25px;

        .Field-CheckboxLabel {
            max-width: unset;
            span {
                display: initial;
            }
        }

        .input-control {
            @include desktop {
                min-width: var(--checkbox-width);
            }
        }
        .Field-ErrorIcon {
            display: none;
        }

        p {
            margin-bottom: 0;
        }
    }
    &-Required {
        color: $grey-dark;
        font-size: 13px;
        margin-left: 7px;
    }
    &-Consent {
        display: flex;
        align-items: center;
    }
}
