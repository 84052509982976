.homepage-big-tiles {
    background: linear-gradient(180deg, rgba(0, 92, 170, 0.045) 0%, rgba(0, 92, 170, 0) 100%);

    &__grid {
        padding: 0 var(--content-padding);
    }

    figure {
        @include from-tablet {
            margin: 0 10px;
        }

        img {
            margin: 30px 0;
            max-width: 620px;
            max-height: unset;
            height: auto;
            object-fit: contain;

            @include tablet {
                margin: 40px 0;
            }

            @include desktop {
                margin: 20px 0;
                max-height: 500px;
            }
        }
    }

    figcaption {
        color: $default-primary-base-color;
        &,
        .homepage-big-tiles__color {
            font-size: 28px;
        }
        @include desktop {
            font-size: 38px;
            margin: 0 auto 0;
        }
        .homepage-big-tiles__color {
            @include desktop {
                font-size: 38px;
            }
        }
    }
}
