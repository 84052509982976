@font-face {
    font-family: 'Gotham';
    src: url(../../../../advox-pilot/src/style/fonts/gotham-bold-webfont.woff2) format('woff2'),
        url(../../../../advox-pilot/src/style/fonts/gotham-bold-webfont.woff) format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Gotham';
    src: url(../../../../advox-pilot/src/style/fonts/gotham-book-webfont.woff2) format('woff2'),
        url(../../../../advox-pilot/src/style/fonts/gotham-book-webfont.woff) format('woff');
    font-style: normal;
}

:root {
    --font-gotham: 'Gotham', sans-serif;
}

body {
    font-family: var(--font-gotham);
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-gotham);
    font-weight: bold;
}
