@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --header-icon-border-color: #{$purple10};
    --header-item-active-border-color: #{$default-secondary-base-color};
    --header-button-active-border-radius: 50px;
}

[dir='ltr'] .Header {
    &-LogoWrapper {
        --header-logo-height: 66px;
        --header-logo-width: 170px;

        @include tablet {
            --header-logo-height: 52px;
            --header-logo-width: 133px;
        }

        @include mobile {
            --header-logo-height: 31px;
            --header-logo-width: 79px;
        }
    }
}
