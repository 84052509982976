@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --header-desktop-padding-left: 140px;
    --header-icon-border-color: #{$default-secondary-base-color};
}

[dir='ltr'] .Header {
    &_isCheckout {
        .Header {
            &-Nav {
                @include desktop {
                    padding-left: var(--content-padding);
                    --header-nav-height: 98px;
                }
            }

            &-LogoWrapper {
                @include desktop {
                    position: static;
                    --header-logo-height: 70px;
                }
            }
        }
    }

    &-LogoWrapper {
        @include desktop {
            position: absolute;
            --header-logo-width: 95px;
            --header-logo-height: 100px;
            left: var(--content-padding);
            top: 15px;
            transform: none;
            z-index: 1;
        }

        @include tablet {
            --header-logo-height: 50px;
            --header-logo-width: 60px;
        }

        @include mobile {
            --header-logo-height: 44px;
            --header-logo-width: 64px;
        }
    }

    &-Nav {
        @include desktop {
            padding-left: calc(var(--content-padding) + var(--header-desktop-padding-left));
        }
    }
}
