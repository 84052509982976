@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --brand-item-background-0: #{#f58b81};
    --brand-item-background-1: #{#eeb933};
    --brand-item-background-2: #{#00aeef};
    --brand-item-background-3: #{#3abea7};
    --brand-item-background-4: #{#0066b3};
    --brand-item-background-5: #{#54bec7};
}

.Brands {
    .AdvoxSlider_variant_default .AdvoxSlider-Heading,
    .AdvoxSlider-Heading {
        font-size: 30px;
    }
}
