@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --brand-item-background-0: #{#f1f8eb};
    --brand-item-background-1: #{#e9f2f1};
    --brand-item-background-2: #{#fff0ee};
    --brand-item-background-3: #{#e6f2fc};
    --brand-item-background-4: #{#e0f6f6};
    --brand-item-background-5: #{#eae5ff};
}

.Brands {
    &_isSlider {
        --brands-background: #{$white};
        --brand-item-height: 274px;
        --brand-item-width: 209px;
        margin: 0 auto 50px;
        padding: 81px 0 0;
        border-top: 1px solid rgba(91, 69, 177, 0.1);

        @include desktop {
            margin: 0 auto 10px;
        }

        .Brands {
            &-Item {
                text-align: center;
                a {
                    color: var(--brand-item-text-color);
                    font-family: var(--brand-item-font-family);
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 136%;
                    letter-spacing: 0;
                }
            }
            &-ImgWrapper {
                min-width: var(--brand-item-width);
                min-height: var(--brand-item-height);

                @include from-tablet {
                    min-width: var(--brand-item-width);
                    min-height: var(--brand-item-height);
                }

                @include widest-desktop {
                    min-width: var(--brand-item-width);
                    min-height: var(--brand-item-height);
                }

                &_index_0 {
                    background: var(--brand-item-background-0);
                }
                &_index_1 {
                    background: var(--brand-item-background-1);
                }
                &_index_2 {
                    background: var(--brand-item-background-2);
                }
                &_index_3 {
                    background: var(--brand-item-background-3);
                }
                &_index_4 {
                    background: var(--brand-item-background-4);
                }
                &_index_5 {
                    background: var(--brand-item-background-5);
                }
            }
        }
    }

    .AdvoxSlider {
        --slider-height: 345px;
        --slider-height-tablet: 345px;
        --slider-height-desktop: 345px;
        @include mobile {
            padding: 0;
        }
        &-Heading {
            @include from-tablet {
                margin: 10px auto 50px;
            }
            &_headingSide_center {
                margin: 10px auto 50px;
            }
        }

        .slick-list {
            @include mobile {
                margin: 0 -10px;
            }
            @include widest-desktop {
                padding: 0 20px;
                margin-left: -15px;
                margin-right: -15px;
                width: calc(100% + 25px);
            }
        }

        .slick-slide {
            @include widest-desktop {
                margin-left: -2px;
            }
            > div {
                @include mobile {
                    margin: 0 10px;
                    padding: 30px 20px;
                }
            }
        }
        .slick-arrow {
            top: calc(((var(--slider-height-desktop) / 2) - 50px));

            @include tablet {
                top: calc(((var(--slider-height-desktop) / 2) - 35px));
            }
            @include desktop {
                top: calc(((var(--slider-height-desktop) / 2) - 35px));
            }
        }
        .slick-prev {
            left: 0;
            @include from-tablet {
                left: -20px;
            }
            @include widest-desktop {
                left: -50px;
            }
        }
        .slick-next {
            right: 0;
            @include from-tablet {
                right: -20px;
            }
            @include widest-desktop {
                right: -50px;
            }
        }
    }
}
