@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --default-notification-height: 40px;
    --notification-info-color: #{$white};
    --notification-info-background: #{$default-primary-base-color};
    --notification-info-height: var(--default-notification-height);
    --notification-error-color: #{$white};
    --notification-error-background: #{$red-light2};
    --notification-error-height: var(--default-notification-height);
    --notification-success-color: #{$white};
    --notification-success-background: #{$default-tertiary-base-color};
    --notification-success-height: var(--default-notification-height);
    --notification-border-radius: 100px;
}
