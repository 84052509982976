@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --content-padding: 20px;
    --content-wrapper-width: calc(1376px + (2 * var(--content-padding)));
}

.HomePage {
    overflow-x: hidden;

    + .SharedTransition.SharedTransition_isVisible {
        display: none;
    }

    .ProductsSlider {
        margin: 70px auto 0;
        padding: 0;
        @include widest-desktop {
            margin: 100px auto 0;
        }

        .slick-slide > div {
            @include tablet {
                max-width: 249px;
                margin: 0 auto;
                padding: 40px 0;
            }
        }

        .slick-list {
            @include tablet {
                padding-right: var(--content-padding);
                padding-right: var(--content-padding);
            }
        }
    }
    .Breadcrumbs {
        display: none;
    }
    .CmsPage-Wrapper {
        padding: 0;
        max-width: 100%;
        overflow-x: hidden;
    }
    .CmsPage .widget {
        overflow-x: inherit;
    }
    &-HeroSliderPlaceholder {
        @include placeholder(450px);
    }
    .CmsPage-Wrapper {
        padding: 0;
        max-width: 100%;
        margin: 0 auto;
    }
    .CmsPage {
        margin: 0 auto;
        .widget {
            overflow-x: inherit;
        }
    }
    .SeoText {
        margin: 40px auto 80px;
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);

        @include widest-desktop {
            margin: 70px auto 100px;
        }
    }
    .NewProducts {
        margin-bottom: 40px;
    }
    .daily-promo-and-products {
        &.ProductsSlider {
            margin: 60px auto 0;
            padding: 0;

            .ProductsSections > div {
                margin: 0 auto;
            }
        }
    }
}
