@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --field-label-container-color: #{$purple30};
}

.Field {
    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_select,
        &_number_withoutButton {
            input:not([value='']),
            input:focus,
            textarea:not(:empty),
            textarea:focus,
            .FieldSelect {
                --input-background-color: #{$white};
                --input-border-color: #{$default-primary-base-color};
            }

            &.Field_isValid {
                input,
                textarea {
                    --input-background-color-focus: #{$white};
                    --input-border-color-focus: #{$default-primary-base-color};
                    --input-background-color: #{$white};
                    --primary-success-color: #{$purple20};
                    &:focus {
                        --primary-success-color: #{$default-primary-base-color};
                    }
                }
            }
        }
    }

    &_hasError {
        input,
        textarea {
            --primary-error-color: #{$red};
            --input-color: #{$red};
            --input-color-focus: #{$red};
            --primary-error-background-color: #{$white};
            --input-background-color: #{$white};
            --input-background-color-focus: #{$white};
            border: 1px solid $red-light2;
        }
        textarea {
            &:focus,
            &:not(:empty) {
                --primary-error-background-color: #{$white};

                & ~ .Field-LabelContainer {
                    label {
                        color: $red;
                    }
                }
            }
        }

        input {
            &:focus,
            &:not([value='']) {
                --primary-error-background-color: #{$white};

                & ~ .Field-LabelContainer {
                    label {
                        color: $red;
                    }
                }
            }
        }

        select {
            --input-background-color: #{$white};
            --input-border-color: #{$red};
        }

        .Field-LabelContainer {
            label {
                color: $red40;
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            &:hover {
                &::after {
                    --checkmark-color: #{$default-primary-base-color};
                }
            }
        }

        &:checked {
            + .input-control {
                border-color: #{$default-primary-base-color};
                background-color: $default-primary-base-color;
            }
        }
    }

    &_type_search {
        .SearchIcon {
            fill: $default-primary-base-color;
        }
    }

    &-SearchInput {
        &:focus {
            border-color: $default-primary-base-color;
        }
    }
}
