h1 {
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-bottom: 40px;
}

h2 {
    font-size: 38px;
    line-height: 115%;
    margin-bottom: 32px;
}

h3 {
    font-size: 32px;
    line-height: 115%;
    margin-bottom: 24px;
}

h4 {
    font-size: 23px;
    line-height: 125%;
    margin-bottom: 20px;
}

h5 {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
}

h6 {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
}
