:root {
    --footer-header-color: #{$default-primary-base-color};
    --footer-column-link-color: #{$grey50};
    --footer-contact-color: #{$default-primary-base-color};
    --footer-social-icon-background: #{$default-secondary-base-color};
}

[dir='ltr'] .Footer {
    --footer-border: 1px solid rgba(0, 92, 170, 0.1);
    .Footer-Contact p a {
        font-weight: bold;
    }
    .Footer-Socials h5,
    .Footer-Contact h5 {
        text-transform: lowercase;
    }
    .Footer-Contact ul li:first-of-type > div {
        color: $grey50;
        opacity: 1;
        max-width: 215px;
    }
    .StoreSwitcher-StoreListWithoutSelectLabel,
    .StoreSwitcher-StoreListWithoutSelectButton {
        color: $grey50;
        opacity: 1;
    }
    .StoreSwitcher-StoreListWithoutSelectContainer:before {
        background: $grey30;
    }
}
