@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --mobile-menu-item-border: #{$blue10};
    --menu-item-caption-special-color: #{$red50};

    --subcategory-item-color: #{$blue80};
    --subcategory-item-active-background: #{$blue5};
    --subcategory-column-border-color: #{$blue5};

    --menu-overlay-background: #{$default-primary-base-color};
}

.Menu {
    &-MenuWrapper {
        @include desktop {
            padding-left: calc(var(--content-padding) + var(--header-desktop-padding-left));
        }
    }
}
