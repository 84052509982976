@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --key-value-table-radio-color: #{$grey-dark};
    --key-value-table-radio-color-active: #{$default-primary-base-color};
}

.KeyValueTable {
    &-Heading {
        font-size: var(--table-font-size);
        border-radius: 7px;
        svg {
            display: none;
            width: 16px;
            min-width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: 17px;
            transform: translateY(-50%);
            rect:first-of-type {
                stroke: var(--key-value-table-radio-color);
            }
        }

        &_isSelected {
            svg rect {
                &:first-of-type {
                    stroke: var(--key-value-table-radio-color-active);
                }

                &:nth-child(2) {
                    fill: var(--key-value-table-radio-color-active);
                }
            }
        }
    }
}
