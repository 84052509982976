@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.AdvoxSlider {
    &_variant_default .AdvoxSlider-Heading,
    &-Heading {
        color: $default-primary-base-color;
        text-transform: lowercase;
        font-weight: bold;
        font-size: 38px;
        line-height: 115%;
        &:before {
            display: none;
        }
    }
}
