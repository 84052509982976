@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ContactPage {
    @include mobile {
        margin-top: 24px;
    }

    &-Heading {
        font-family: $font-primary;
        color: $default-primary-base-color;
    }

    .ContactForm {
        .Field:first-of-type {
            @include from-tablet {
                margin-top: 0;
            }
        }
    }
}
