@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.CarouselScrollArrow {
    $arrow-size: 20px;
    z-index: 1;
    transition: opacity 200ms;
    cursor: pointer;

    width: $arrow-size;
    height: $arrow-size;

    .ChevronIcon {
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: scale(1.2);
    }

    &_isNextArrow {
        transform: none;

        &:hover {
            transform: scale(1.2);
        }
    }

    &_isDisabled {
        opacity: 0;
        cursor: default;
    }

    &_isInvisible {
        visibility: hidden;
    }
}
