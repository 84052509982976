@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --field-label-container-color: #{$grey3};
    --field-label-container-color-error: #{$red70};
    --field-label-container-color-error-focus: #{$red70};
}
.Field {
    --input-checkbox-radius: 3px;
    --checkmark-color: #{$white};
    position: relative;

    input[value=''] + .Field-InputPasswordBtn svg path {
        --input-placeholder-color: $grey3;
    }
    input:disabled::placeholder {
        color: $grey4;
    }
    input:disabled + .Field-InputPasswordBtn svg path {
        fill: $grey4;
    }
    @include mobile {
        margin-block-start: 24px;
        --default-input-padding: 16px 24px;
    }
    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_select,
        &_number_withoutButtons {
            textarea::placeholder,
            input::placeholder {
                color: transparent;
            }

            .Field-LabelContainer {
                display: flex;
                align-items: center;
                position: absolute;
                top: 17px;
                left: 30px;
                z-index: 2;
                pointer-events: none;
                order: 2;
                transition: 0.1s all ease-out;

                label {
                    font-size: 15px;
                    color: var(--field-label-container-color);
                    padding: 0;
                }

                .ChevronIcon {
                    width: 23px;
                }
            }

            input:not([value='']),
            input:focus,
            textarea:not(:empty),
            textarea:focus,
            .FieldSelect {
                --input-background-color: #{$white};
                --input-border-color: #{$grey-dark};

                & + .Field-LabelContainer {
                    background: $white;
                    padding: 0 4px;
                    top: -10px;
                    left: 26px;

                    label {
                        font-size: 13px;
                    }
                }
            }

            &.Field_isValid {
                input,
                textarea {
                    --input-border-color-focus: #{$orange50};
                    --input-background-color: #{$white};
                    --primary-success-color: #{$grey-dark};
                }
            }
        }

        &_number_withoutButtons {
            display: block;
        }
    }

    &_hasError {
        input,
        textarea {
            --primary-error-color: #{$red70};
            --input-color: #{$red70};
            --input-color-focus: #{$red70};
            --primary-error-background-color: #{$white};
            --input-background-color: #{$red5};
            --input-background-color-focus: #{$white};
            border: 1px solid $red70;
        }
        textarea {
            &:focus,
            &:not(:empty) {
                --primary-error-background-color: #{$red5};

                & ~ .Field-LabelContainer {
                    label {
                        color: var(--field-label-container-color-error);
                    }
                }
            }
        }

        input {
            &:focus,
            &:not([value='']) {
                --primary-error-background-color: #{$red5};

                & ~ .Field-LabelContainer {
                    label {
                        color: var(--field-label-container-color-error-focus);
                    }
                }
            }
        }

        select {
            --input-background-color: #{$red5};
            --input-border-color: #{$red70};
        }

        .Field-LabelContainer {
            label {
                color: var(--field-label-container-color-error);
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            cursor: pointer;
            border-radius: var(--input-checkbox-radius);
            border-width: 1px;
            border-color: $grey3;
        }
        &:active {
            + .input-control {
                --box-color: transparent;
                &::after {
                    --checkmark-color: #{$default-secondary-base-color};
                }
            }
        }
        + .input-control {
            &:hover {
                @include desktop {
                    --box-color: transparent;
                    &::after {
                        --checkmark-color: #{$default-secondary-base-color};
                    }
                }
            }
        }

        &:checked {
            + div.input-control {
                border-color: #{$default-secondary-base-color};
                background-color: $default-secondary-base-color;
                &::after {
                    --checkmark-color: #{$white};
                }
            }
        }
    }
    &-InputPasswordContainer {
        position: relative;

        input {
            padding-right: 50px;
        }

        &_isDisabled {
            .Field-InputPasswordBtn svg {
                cursor: default;
                touch-action: none;
            }
        }
    }
    &-InputPasswordBtn {
        position: absolute;
        right: 32px;
        top: 15px;
        max-width: 30px;
        height: 30px;
        line-height: 0.5;
        cursor: pointer;

        &:after {
            display: block;
            position: absolute;
            inset: 0;
            content: '';
            z-index: 1;
        }
    }
    &-ErrorIcon {
        position: absolute;
        right: 15px;
        top: 18px;
        z-index: 1;
        pointer-events: none;
    }

    &_type_select {
        .Field-ErrorIcon {
            & + .FieldSelect {
                .ChevronIcon {
                    right: 45px;
                }
            }
        }
    }

    &_type_search {
        position: relative;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .SearchIcon {
            position: absolute;
            right: 18px;
            fill: $default-secondary-base-color;
            width: 16px;
            height: 16px;
        }
    }

    &_type_password {
        .Field-ErrorIcon {
            display: none;
        }
        &.Field_hasError {
            .Field-InputPasswordContainer {
                .Field-InputPasswordBtn {
                    right: 19px;
                }
            }
        }
    }

    &-SearchInput {
        width: 100%;
        background-color: $white;
        padding: 11px 35px 11px 16px;
        border-color: $grey30;
        border-width: 1.5px;
        font-size: 13px;
        line-height: 20px;
        color: $grey70;

        &:focus {
            border-color: $default-secondary-base-color;
            border-width: 1.5px;
            color: $grey70;
        }
    }
}
