@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --reset-button-label-color: #{$white};
    --reset-button-background: #{$default-primary-base-color};
    --reset-button-border-radius: 5px;
    --reset-button-icon-color: #{$white};
}

.ResetButton {
    display: flex;
    align-items: center;
    background: var(--reset-button-background);
    padding: 7px 36px 7px 12px;
    border-radius: var(--reset-button-border-radius);
    position: relative;
    cursor: pointer;

    &-Label {
        font-size: 13px;
        font-weight: 700;
        color: var(--reset-button-label-color);
        font-family: $font-primary;
    }

    &-CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 36px;

        .CloseIcon {
            width: 24px;
            height: 24px;
            fill: var(--reset-button-icon-color);
        }
    }
}
