.homepage-category-tiles {
    @media (min-width: 1024px) and (max-width: 1281px) {
        margin-top: 0;
        flex-wrap: wrap;
        max-width: 665px;
        padding-top: 81px;
        padding-bottom: 13px;
        position: static;
    }
    @include wider-desktop {
        flex-wrap: nowrap;
    }

    a {
        @media (min-width: 1024px) and (max-width: 1281px) {
            margin-bottom: 67px;
        }
        &:nth-child(2) {
            @include wider-desktop {
                order: 3;
            }
        }

        &:not(:last-of-type) {
            margin-right: 0;
        }
    }

    span {
        color: $default-primary-base-color;
        font-weight: bold;
        font-size: 23px;
        line-height: 125%;

        strong {
            color: $default-secondary-base-color;
        }
    }
}
