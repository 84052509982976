@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.HomePage {
    .CmsPage {
        margin-top: -30px;
        --cms-page-heading-color: #{$default-primary-base-color};
        @include large-screen {
            margin-top: -85px;
        }

        @include mobileAndTablet {
            margin-top: 0;
        }
        .ProductsSlider {
            @include tablet {
                margin: 30px auto 0;
            }
            @include widest-desktop {
                margin: 50px auto 0;
            }
        }
        .block-static-block {
            &:first-of-type {
                &:after {
                    @include wider-desktop {
                        content: '';
                        width: 258px;
                        height: 258px;
                        display: block;
                        position: absolute;
                        left: -86px;
                        bottom: -100px;
                        z-index: 2;
                        background: url('../../assets/svg/shape-1.svg') no-repeat center center;
                    }
                }
            }
            &:nth-of-type(2) {
                &:after {
                    @include wider-desktop {
                        content: '';
                        width: 220px;
                        height: 220px;
                        display: block;
                        position: absolute;
                        right: -55px;
                        bottom: -120px;
                        z-index: 2;
                        background: url('../../assets/svg/shape-2.svg') no-repeat center center;
                    }
                }
            }
        }

        .homepage-big-tiles {
            @include mobile {
                margin-top: 250px;
            }
            @include desktop {
                margin-top: 50px;
            }

            &:after {
                @include wider-desktop {
                    content: '';
                    width: 187px;
                    height: 187px;
                    display: block;
                    position: absolute;
                    left: -35px;
                    bottom: 250px;
                    z-index: 2;
                    background: url('../../assets/svg/shape-3.svg') no-repeat center center;
                }
            }
            .homepage-big-tiles__grid figure {
                &:first-of-type {
                    @include mobile {
                        margin-top: -300px;
                    }
                }
            }
        }
    }
}
