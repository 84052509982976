@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --currency-switcher-color: #{$default-primary-base-color};
}

[dir='ltr'] .CurrencySwitcher {
    border-bottom: none;

    @include desktop {
        padding-left: 30px;
        margin-left: 24px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2px;
            height: 43px;
            background: var(--header-icon-border-color);
        }
    }

    @include mobileAndTablet {
        margin: 10px 30px;
    }

    .FieldSelect {
        @include desktop {
            width: auto;
            min-width: 0;
            padding-left: 0;
        }

        &-Select {
            color: var(--store-switcher-color);
            border: 1px solid var(--input-border-color);

            @include desktop {
                font-size: 15px;
                font-weight: 400;
                border: none;
                padding: 0;
                --store-switcher-color: #{$default-secondary-base-color};
                border-radius: 0;
            }

            @include mobileAndTablet {
                padding: 10px 32px;
                font-weight: 700;
            }

            &_isExpanded {
                border-bottom: 0;
            }
        }

        &-Options {
            &_isExpanded {
                @include desktop {
                    border-radius: 0;
                    background-color: var(--store-switcher-list-background);
                    border: 1px solid var(--input-border-color);
                    min-width: 80px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &-Option {
            &,
            &:first-of-type,
            &:last-of-type {
                @include desktop {
                    padding: 0;
                    margin: 5px 0;
                    text-align: center;
                }
            }

            &:last-of-type {
                @include mobileAndTablet {
                    margin-bottom: 5px;
                }
            }
        }

        .ChevronIcon {
            fill: var(--store-switcher-color);

            @include desktop {
                width: 10px;
                height: 10px;
                --store-switcher-color: #{$default-secondary-base-color};
            }

            @include mobileAndTablet {
                right: 32px;
            }
        }
    }
}
